<template>
  <div class="bitts-tree">
    <tree
      :checked-keys="checkedKeys"
      :expanded-keys="expandedAndEnabledKeys"
      :auto-expand-parent="autoExpandParent"
      :selected-keys="selectedKeys"
      :show-line="showLine"
      :show-icon="true"
      :checkable="showCheckboxes"
      :tree-data="treeData"
      :multiple="multiSelect"
      :selectable="false"
      :disabled="disableExpansion"
      @expand="onExpand"
      @select="onSelect"
      @check="onCheck"
    >
      <!-- Override for toggle arrows -->
      <template #switcherIcon>
        <span class="text-neutral-text-placeholder">
          <FontAwesomeIcon
            :icon="['fas', 'chevron-down']"
            :style="{ height: '12px', width: '12px' }"
          />
        </span>
      </template>

      <!-- SLOT FOR NORMAL TITLES -->
      <template #title="info">
        <div
          v-if="info?.scopedSlots?.title === 'data-source'"
          :class="{
            'max-w-[356px]': !info.hasIcon,
            'max-w-[382px]': info.hasIcon && !info.value.relationship_id,
            'max-w-[356px]': info.hasIcon && info.value.relationship_id,
          }"
          class="w-full inline-flex justify-between items-center ml-4"
        >
          <component
            :is="tooltipOrDiv(info)"
            v-bind="tooltipProps(info)"
            class="overflow-ellipsis whitespace-nowrap overflow-hidden"
            @cta-clicked="$emit('tooltip-cta-clicked')"
          >
            <span
              v-if="info.lowercaseTitle?.indexOf(normalizedSearchValue) > -1"
            >
              <span>{{ prestring(info) }}</span
              ><!--
              --><span class="c-bitts-tree_data-source-search"
                >{{ searchstring(info)
                }}<!--
              --></span
              ><!--
              --><span>{{ poststring(info) }}</span>
            </span>

            <span v-else>{{ info.title }}</span>

            <span v-if="info.isRequired && info.blockedPops.length">
              <BittsTooltip
                :mount-to-body="true"
                placement="top"
                class="inline-flex pl-8 cursor-pointer"
              >
                <FontAwesomeIcon
                  :icon="['fas', 'info-circle']"
                  :style="{ height: '14px', width: '14px' }"
                  class="text-neutral-accent"
                />

                <template #title>
                  In order to un-sync this field it must be removed from any
                  population definitions
                </template>
              </BittsTooltip>

              <div class="c-bitts-tree_population-links">
                <span v-for="(pop, index) in info.blockedPops" :key="pop.id">
                  <span v-if="index === 0" class="text-neutral-text">
                    Used in the&nbsp;
                  </span>

                  <a
                    class="cursor-pointer inline-flex flex-nowrap items-center max-w-full"
                    @click="goToPopulation(pop.id)"
                  >
                    <span class="flex-shrink truncate text-neutral-text-weak">
                      {{ pop.name }} Population
                    </span>

                    <FontAwesomeIcon
                      :icon="['fas', 'fa-arrow-up-right']"
                      :style="{
                        height: '8px',
                        width: '8px',
                        color: 'currentColor',
                      }"
                      class="text-white bg-info-accent ml-4 rounded p-2"
                    />
                  </a>
                </span>
              </div>
            </span>

            <template #title>
              {{ info.fieldTooltipText }}
            </template>
          </component>

          <span
            v-if="info.isRequired && !info.blockedPops.length"
            class="text-xs text-neutral-text-weak/50"
          >
            Required for {{ info.tableName }} Object
          </span>

          <BittsTooltip
            v-else-if="info.hasIcon"
            :mount-to-body="true"
            overlay-class="c-bitts-tree_question-tooltip-overlay cursor-default"
            placement="top"
            @click.stop
          >
            <FontAwesomeIcon
              :icon="['far', 'file-magnifying-glass']"
              :style="{ height: '16px', width: '16px', color: 'currentColor' }"
              class="text-neutral-accent"
              @click.stop
            />

            <template #title>
              <div class="c-bitts-tree_question-tooltip">
                {{ info.tooltipText }}
              </div>
            </template>
          </BittsTooltip>
        </div>

        <div
          v-else-if="info.scopedSlots?.title === 'avatar'"
          class="flex items-center"
        >
          <BittsAvatar
            :org="getOrg(info)"
            :show-initials="true"
            size="x-small"
            shape="square"
            class="mr-8"
          />
          {{ info.title }}
        </div>

        <div v-else-if="info.scopedSlots?.imgUrl" class="flex items-center">
          <img :src="info.scopedSlots.imgUrl" class="w-16 h-16 mr-8" />
          {{ info.title }}
        </div>

        <div
          v-else-if="info.scopedSlots?.title === 'prefix-icon'"
          class="flex items-center"
        >
          <FontAwesomeIcon
            :icon="info.prefixIcon"
            :style="{ height: '14px', width: '14px', color: 'currentColor' }"
            :class="info.prefixIconClasses"
          />
          {{ info.title }}
        </div>

        <div
          v-else-if="info.scopedSlots?.title === 'cta'"
          class="inline-flex items-center"
        >
          <span class="mr-8" @click="toggleExpand(info)">
            {{ info.title }}
          </span>
          <slot name="cta" :info="info" />
        </div>

        <span
          v-else-if="info.scopedSlots?.title === 'button'"
          class="inline-flex items-center"
        >
          <span class="mr-16" @click="toggleExpand(info)">
            {{ info.title }}
          </span>

          <BittsButton
            :type="info.buttonType || 'primary'"
            :text="info.buttonText"
            size="x-small"
            :class="info.buttonClasses || ''"
            @click.prevent="info.onClick"
          />
        </span>

        <span
          v-else-if="info.scopedSlots?.title === 'beta'"
          class="inline-flex items-center"
        >
          <span class="mr-4" @click="toggleExpand(info)">
            {{ info.title }}
          </span>

          <BittsTag
            color="beta"
            class="flex items-center mr-8"
            variant="rounded"
          >
            Beta
          </BittsTag>
        </span>

        <span
          v-else-if="info.scopedSlots?.title === 'question'"
          class="inline-flex items-center"
        >
          <span class="mx-4" @click="toggleExpand(info)">
            {{ info.title }}
          </span>

          <BittsTooltip
            :mount-to-body="true"
            overlay-class="c-bitts-tree_question-tooltip-overlay"
            placement="bottom"
          >
            <FontAwesomeIcon
              :icon="[info.questionType || 'far', 'question-circle']"
              :style="{ height: '14px', width: '14px', color: 'currentColor' }"
              class="text-neutral-text-placeholder mr-4"
            />

            <template #title>
              <div class="c-bitts-tree_question-tooltip">
                {{ info.tooltipText }}
              </div>
            </template>
          </BittsTooltip>
        </span>

        <span v-else-if="info.scopedSlots?.title === 'non-expanding-title'">
          {{ info.title }}
        </span>

        <span
          v-else-if="info.scopedSlots?.title === 'visibility-eyes'"
          class="inline-flex items-center"
        >
          <span class="mx-4" @click="toggleExpand(info)">
            {{ info.title }}
          </span>

          <span class="text-neutral-background-disabled mr-4">|</span>

          <BittsTooltip :mount-to-body="true">
            <FontAwesomeIcon
              :icon="['fas', info.showEye ? 'eye' : 'eye-slash']"
              :style="{ height: '14px', width: '14px', color: 'currentColor' }"
              class="text-primary-border mr-4"
            />

            <template #title>{{ info.tooltipText }}</template>
          </BittsTooltip>
          <span class="text-neutral-text-weak text-sm">{{ info.eyeNum }}</span>
        </span>

        <span v-else class="mx-4" @click="toggleExpand(info)">
          {{ info.title }}

          <BittsTag
            v-if="info.scopedSlots?.suffix?.includes('tag')"
            :color="
              info.scopedSlots.suffix === 'standard-tag' ? 'info' : 'neutral'
            "
            size="x-small"
            variant="rounded"
            class="ml-8"
          >
            {{
              info.scopedSlots.suffix === 'standard-tag'
                ? 'Standard'
                : 'Required for Greenfield'
            }}
          </BittsTag>
        </span>
      </template>
    </tree>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Tree } from 'ant-design-vue';

import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsButton from '../BittsButton/BittsButton.vue';
import BittsTag from '../BittsTag/BittsTag.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

export default {
  name: 'BittsTree',
  components: {
    Tree,
    BittsAvatar,
    BittsButton,
    BittsTag,
    BittsTooltip,
    FontAwesomeIcon,
  },
  props: {
    expanded: {
      type: Array,
      default: () => [],
    },
    treeData: {
      type: Array,
      default: () => [],
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    includeNodeInfo: {
      type: Boolean,
      default: false,
    },
    showCheckboxes: {
      type: Boolean,
      default: true,
    },
    searchValue: {
      type: String,
      default: '',
    },
    checkedKeys: {
      type: Array,
      default: () => [],
    },
    disableExpansion: {
      type: Boolean,
      default: false,
    },
    initialExpandedKeys: {
      type: Array,
      default: null,
    },
  },
  emits: ['check', 'tooltip-cta-clicked'],
  data() {
    return {
      autoExpandParent: true,
      expandedKeys: [],
      selectedKeys: [],
    };
  },
  computed: {
    expandedAndEnabledKeys() {
      return !this.disableExpansion ? this.expandedKeys : [];
    },
    normalizedSearchValue() {
      return this.searchValue ? this.searchValue.toLowerCase() : null;
    },
  },
  watch: {
    expanded() {
      if (this.expanded?.length > 0) this.expandedKeys = this.expanded;
    },
    searchValue() {
      this.autoExpandParent = true;
      const expandedKeysForSearch = this.expandNodeWithSearchedChildren(
        this.treeData,
        this.normalizedSearchValue,
        [],
      );
      this.expandedKeys = expandedKeysForSearch;
      if (!this.normalizedSearchValue) this.expandedKeys = [];
    },
  },
  created() {
    if (this.initialExpandedKeys?.length > 0)
      this.expandedKeys = this.initialExpandedKeys;
    if (this.expanded?.length > 0) this.expandedKeys = this.expanded;
  },
  methods: {
    expandNodeWithSearchedChildren(nodes, val) {
      let newExpandedKeys = [];
      nodes.forEach((node) => {
        const hasChildren = !!node.children;
        // fake "title" nodes like lookup fields don't have value, we don't want to filter those
        const hasValue = !!node.value;
        if (node.lowercaseTitle.indexOf(val) > -1 || !val) {
          newExpandedKeys.push(node.key);
          if (!hasChildren && hasValue) {
            node.class = node.class.replace(
              'c-bitts-tree__not-search-result',
              '',
            );
          }
        } else if (
          !node.class.includes('c-bitts-tree__not-search-result') &&
          !hasChildren &&
          hasValue
        ) {
          node.class += ' c-bitts-tree__not-search-result';
        }
        if (!hasChildren || !node.value) return;
        newExpandedKeys = [
          ...newExpandedKeys,
          ...this.expandNodeWithSearchedChildren(
            node.children,
            val,
            newExpandedKeys,
          ),
        ];
        if (this.searchFunction) {
          this.searchFunction(node, val);
        }
      });
      return newExpandedKeys;
    },
    getOrg(info) {
      if (info.domain) {
        return { domain: info.domain };
      }
      return info.org;
    },
    onSelect(selectedKeys) {
      this.selectedKeys = selectedKeys;
    },
    poststring({ title, lowercaseTitle }) {
      return title.substring(
        lowercaseTitle.indexOf(this.normalizedSearchValue) +
          this.normalizedSearchValue.length,
      );
    },
    prestring({ title, lowercaseTitle }) {
      return title.substring(
        0,
        lowercaseTitle.indexOf(this.normalizedSearchValue),
      );
    },
    searchstring({ title, lowercaseTitle }) {
      return title.substring(
        lowercaseTitle.indexOf(this.normalizedSearchValue),
        lowercaseTitle.indexOf(this.normalizedSearchValue) +
          this.normalizedSearchValue.length,
      );
    },
    toggleExpand({ children, key }) {
      if (!this.disableExpansion && !this.expanded) {
        const expandedIndex = this.expandedKeys.indexOf(key);
        if (children) {
          expandedIndex > -1
            ? this.expandedKeys.splice(expandedIndex, 1)
            : this.expandedKeys.push(key);
        }
      }
    },
    tooltipOrDiv(info) {
      return info.fieldTooltipText ? 'BittsTooltip' : 'div';
    },
    tooltipProps(info) {
      let props = {};
      if (info.fieldTooltipText) {
        props = {
          hideArrow: false,
          mountToBody: true,
          overlayClass: 'c-bitts-tree_question-tooltip-overlay',
          placement: 'top',
          trigger: 'hover',
        };
        if (info.includeCta) {
          props = {
            ...props,
            buttonText: info.buttonText,
            learnMoreLink: info.learnMoreLink,
            includeCta: true,
          };
        }
      }
      return props;
    },
    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys, nodeInfo) {
      nodeInfo.nativeEvent.stopPropagation();
      if (!this.includeNodeInfo) {
        this.$emit('check', checkedKeys);
      } else {
        this.$emit('check', {
          checkedKeys,
          nodeInfo,
        });
      }
    },
    goToPopulation(id) {
      this.$router.push({
        name: 'edit_population',
        params: { population_id: id },
      });
    },
  },
};
</script>

<style lang="pcss">
.bitts-tree {
  .ant-tree {
    background-color: transparent;
    font-family: inherit;
  }
  .ant-tree.ant-tree-show-line li::before {
    left: 9px;
    content: ' ';
    height: calc(100% - 10px);
    top: 10px;
    @apply w-px border-l border-neutral-background-disabled m-0 absolute;
  }

  .ant-tree-switcher.ant-tree-switcher_open,
  .ant-tree-switcher.ant-tree-switcher_close {
    background: none;
  }

  .checkable-title .ant-tree-switcher_open,
  .checkable-title .ant-tree-switcher_close {
    @apply mr-5;
  }
  .ant-tree-switcher_open svg {
    @apply text-neutral-400;
  }
  .ant-tree.ant-tree-show-line > .ant-tree-treenode-switcher-close::before {
    border: none;
  }

  .ant-tree-switcher {
    @apply transition-all;
  }
  .ant-tree-switcher_close {
    transform: rotate(-90deg);
  }

  .ant-tree-child-tree.ant-tree-child-tree-open
    li:not(.ant-tree-treenode-switcher-open)::before {
    border: none;
  }
  .ant-tree > li:first-child {
    @apply pt-0;
  }
  .ant-tree-child-tree > li:first-child {
    @apply pt-6;
  }
  .ant-tree > li:last-child {
    padding-bottom: 0;
  }
  .ant-tree .ant-tree-node-content-wrapper {
    @apply p-0 text-neutral-text-strong;
  }
  .ant-tree li span.ant-tree-switcher {
    @apply w-20 m-0;
  }
  .ant-tree li span.ant-tree-iconEle {
    @apply m-0;
  }
  .ant-tree li .ant-tree-node-content-wrapper {
    height: auto;
  }
  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background: none !important;
  }
  .ant-tree-switcher-noop {
    display: none !important;
  }
  .ant-tree li ul {
    margin: 0;
    padding: 0 0 0 20px;
  }
  .ant-tree-iconEle.ant-tree-icon__customize {
    @apply ml-0;

    /* This helps center icons which return 404s, see the BittsAvatar component */
    div {
      @apply w-full h-full flex justify-center items-center;
    }
  }
  .ant-tree .ant-tree-checkbox {
    margin-block-start: 0;
  }
  .ant-tree li span.ant-tree-checkbox {
    @apply mr-4;
  }
  .ant-avatar-square {
    @apply border border-solid border-neutral-background-disabled;
    border-radius: 3px;
  }
  li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper button {
    @apply cursor-pointer;
  }
  li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper button span {
    color: inherit !important;
    @apply cursor-pointer;
  }
}
/* Mounts outside of component so need to directly call */
.c-bitts-tree_data-source-search {
  @apply font-bold text-neutral-text-strong bg-info-background-weak rounded-b-bts-xs;
}

.c-bitts-tree_question-tooltip-overlay {
  @apply flex items-center;
  .ant-tooltip-inner {
    @apply opacity-100 bg-neutral-background-strong;
  }
}

.c-bitts-tree_question-tooltip-overlay svg:focus {
  @apply outline-none;
}

.c-bitts-tree_question-tooltip {
  @apply flex items-center flex-wrap;
  max-width: 200px;
}

.c-bitts-tree_tooltip {
  border: none;
}

.c-bitts-tree__not-search-result {
  @apply hidden !important;
}

.c-bitts-tree_population-links {
  @apply flex bg-neutral-background-weak flex-col p-8 mr-16 cursor-default;
}

.ant-tree-checkbox-wrapper:hover {
  @apply text-secondary-background-medium;
}

.ant-tree-checkbox-checked::after {
  @apply border-none;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  @apply bg-secondary-background-medium;
}

.ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner {
  @apply border-neutral-border;
}

.ant-tree
  .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled):hover
  .ant-tree-checkbox-inner,
.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  @apply bg-secondary-background-medium border-secondary-background-medium border-2;
}

.ant-tree
  .ant-tree-checkbox:not(.ant-tree-checkbox-disabled):hover
  .ant-tree-checkbox-inner {
  @apply border-neutral-border-focus;
}

.ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner::after {
  @apply w-[4px] h-[9px] border-[1.8px] border-white;
}

.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  @apply w-[8px] h-[8px];
}

.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  @apply bg-secondary-background-medium;
}

.ant-tree-checkbox-inner {
  @apply border transition-none border-neutral-border rounded-3;
}

.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  @apply border-neutral-border-focus border-2;
}

.ant-tree .ant-tree-checkbox + span:hover {
  background: transparent;
}

.ant-tree-checkbox-wrapper:hover + .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-inner:hover {
  @apply border-2 border-neutral-border-focus;
}

.ant-tree-checkbox-disabled {
  .ant-tree-checkbox-inner {
    @apply opacity-50 cursor-not-allowed;
  }
  &.ant-tree-checkbox-checked {
    .ant-tree-checkbox-inner::after {
      @apply border-white;
    }
    .ant-tree-checkbox-inner {
      @apply border-2 rounded-3;
      border-color: theme(colors.info.border) !important;
    }
  }
  &:not(.ant-tree-checkbox-checked) {
    .ant-tree-checkbox-inner {
      @apply bg-white;
    }
  }
}
</style>
